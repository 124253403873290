import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  token: '',
  loading: false,
  role: '',
  success: '',
  error: '',
  user: {},
};

const loginSlice = createSlice({
  name: 'login',
  initialState: initialState,
  reducers: {
    login(state) {
      state.loading = true;
    },
    loginSuccess(state, { payload }) {
      state.token = payload.token;
      state.role = payload.role;
      state.user = payload.user;
      state.loading = false;
    },
    loginFailure(state, { payload }) {
      state.success = '';
      state.error = payload.message;
      state.loading = false;
    },
    logOutUser(state) {
      state.token = '';
      state.role = '';
    },
  },
});

export const { login, loginSuccess, loginFailure, logOutUser } =
  loginSlice.actions;

const persistConfig = {
  keyPrefix: 'merchX-',
  key: 'login',
  storage,
};

export default persistReducer(persistConfig, loginSlice.reducer);
