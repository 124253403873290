import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
// import App from './App';
import './assets/reset.scss';
import './assets/scss/theme.scss';
import Loading from './Components/Atoms/Loading';
// import SocketProvider from './Context/SocketProvider';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import store from './Store/store';
const App = React.lazy(() => import('./App'));

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate persistor={store.__persistor} loading={false}>
          {/* <SocketProvider> */}
          <Suspense fallback={<Loading />}>
            <App />
          </Suspense>
          {/* </SocketProvider> */}
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
