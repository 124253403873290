import loginSlice from './Auth/Login/loginSlice';
import billSlice from './Bill/billSlice';
import campaignSlice from './Campaign/campaignSlice';
import CategorySlice from './Category/CategorySlice';
import contentSlice from './Content/contentSlice';
import deviceSlice from './Device/deviceSlice';
import LayoutSlice from './Layout/LayoutSlice';
import PackageSlice from './Package/PackageSlice';
import playListSlice from './Playlist/playlistSlice';
import SlotSlice from './Slot/SlotSlice';
import UserSlice from './User/UserSlice';

export const rootReducers = {
  login: loginSlice,
  layout: LayoutSlice,
  user: UserSlice,
  category: CategorySlice,
  package: PackageSlice,
  content: contentSlice,
  device: deviceSlice,
  playlist: playListSlice,
  campaign: campaignSlice,
  bill: billSlice,
  slot: SlotSlice,
};
