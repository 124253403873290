import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  billData: {},
  bills: [],
  loading: false,
  error: '',
  singleBill: {},
  adding: false,
  contentSummaryData: {},
  contentSummary: [],
  generatedBillData: [],
  billReport: [],
  billReportData: {},
  contentReport: [],
  contentReportData: {},
};

const billSlice = createSlice({
  name: 'Bill',
  initialState: initialState,
  reducers: {
    storeBillData: (state, action) => {
      state[action.payload.name] = action.payload.data;
    },
    getBill: (state, action) => {
      state.loading = true;
    },
    getBillSuccess: (state, action) => {
      state.billData = action.payload;
      state.bills = action.payload?.bills;
      state.loading = false;
    },
    getBillFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getBillReport: (state, action) => {
      state.loading = true;
    },
    getBillReportSuccess: (state, action) => {
      state.billReportData = action.payload;
      state.billReport = action.payload?.bills;
      state.loading = false;
    },
    getBillReportFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getContentReport: (state, action) => {
      state.loading = true;
    },
    getContentReportSuccess: (state, action) => {
      state.contentReportData = action.payload;
      state.contentReport = action.payload?.contentDetails;
      state.loading = false;
    },
    getContentReportFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    getContentSummary: (state, action) => {
      state.loading = true;
    },
    getContentSummarySuccess: (state, action) => {
      state.contentSummaryData = action.payload;
      state.contentSummary = action.payload?.contentSummary;
      state.loading = false;
    },
    getContentSummaryFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addBill: (state, action) => {
      state.adding = true;
    },
    addBillSuccess: (state, action) => {
      state.adding = false;
      state.contentSummary = [];
      state.contentSummaryData = {};
      state.generatedBillData = [];
      // state.createBillData = action.payload;
    },
    addBillFailure: (state, action) => {
      state.adding = false;
      state.error = action.payload.message;
    },
    payBill: (state, action) => {
      state.adding = true;
    },
    payBillSuccess: (state, action) => {
      state.adding = false;
      // state.createBillData = action.payload;
    },
    payBillFailure: (state, action) => {
      state.adding = false;
      state.error = action.payload.message;
    },
    getSingleBill: (state, action) => {
      state.loading = true;
    },
    getSingleBillSuccess: (state, action) => {
      state.singleBill = action.payload;
      state.loading = false;
    },
    getSingleBillFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteBill: (state, action) => {
      state.adding = true;
    },
    deleteBillSuccess: (state, action) => {
      state.adding = false;
    },
    deleteBillFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getBill,
  getBillSuccess,
  getBillFailure,
  addBill,
  addBillSuccess,
  addBillFailure,
  payBill,
  payBillSuccess,
  payBillFailure,
  getSingleBill,
  getSingleBillSuccess,
  getSingleBillFailure,
  getBillReport,
  getBillReportFailure,
  getBillReportSuccess,
  getContentReport,
  getContentReportFailure,
  getContentReportSuccess,
  deleteBill,
  deleteBillSuccess,
  deleteBillFailure,
  storeBillData,
  getContentSummary,
  getContentSummarySuccess,
  getContentSummaryFailure,
} = billSlice.actions;

export default billSlice.reducer;
