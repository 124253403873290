import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  playlistData: {},
  playlists: [],
  playListContent: [],
  listDevice: [],
  screenLayout: '',
  createPlaylistData: {},
  loading: false,
  error: '',
  singlePlaylist: {},
  adding: false,
};

const playlistSlice = createSlice({
  name: 'Playlist',
  initialState: initialState,
  reducers: {
    storePlaylistData: (state, action) => {
      state[action.payload.name] = action.payload.data;
    },
    getPlayList: (state, action) => {
      state.loading = true;
    },
    getPlayListSuccess: (state, action) => {
      state.playlistData = action.payload;
      state.playlists = action.payload?.playLists;
      state.loading = false;
    },
    getPlayListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addPlayListData: (state, action) => {
      state.adding = true;
    },
    addPlayListDataSuccess: (state, action) => {
      state.adding = false;
      // state.createPlaylistData = action.payload;
    },
    addPlayListDataFailure: (state, action) => {
      state.adding = false;
      state.error = action.payload.message;
    },
    getSinglePlayList: (state, action) => {
      state.loading = true;
    },
    getSinglePlayListSuccess: (state, action) => {
      if (!action.payload?.campaign) {
        const { contents, devices, screenLayout, orgId, categoryId, ...rest } =
          action.payload;
        state.playListContent = contents;
        if (devices?.length) {
          devices?.forEach((data) => {
            data.selected = false;
          });
        }
        state.singlePlaylist = action.payload;
        state.screenLayout = screenLayout;
        state.listDevice = devices;
        state.createPlaylistData = {
          ...rest,
          orgId: orgId?._id,
          categoryId: categoryId?._id,
        };
        state.loading = false;
      } else {
        const { playlist } = action.payload;
        state.playListContent = playlist?.contents;
      }
    },
    getSinglePlayListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deletePlayList: (state, action) => {
      state.adding = true;
    },
    deletePlayListSuccess: (state, action) => {
      state.adding = false;
    },
    deletePlayListFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getPlayList,
  getPlayListSuccess,
  getPlayListFailure,
  addPlayListData,
  addPlayListDataSuccess,
  addPlayListDataFailure,
  getSinglePlayList,
  getSinglePlayListSuccess,
  getSinglePlayListFailure,
  deletePlayList,
  deletePlayListSuccess,
  deletePlayListFailure,
  storePlaylistData,
} = playlistSlice.actions;

export default playlistSlice.reducer;
