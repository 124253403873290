import { call, put, takeEvery } from 'redux-saga/effects';
import { del, get, patch, post } from '../../helpers/api_helper';
import { toaster } from '../../helpers/custom/Toast';
import {
  addUserData,
  deleteUser,
  deleteUserFailure,
  deleteUserSuccess,
  getSingleUser,
  getSingleUserFailure,
  getSingleUserSuccess,
  getUserList,
  getUserListFailure,
  getUserListSuccess,
} from './UserSlice';

function* onGetUser({ payload: { page = 1, limit, role } }) {
  try {
    const url = `/users/user-list?userRole=${role}&limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getUserListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || 'Get Users Failed';
    yield put(getUserListFailure({ message }));
  }
}

function* onAddUserUser({
  payload: {
    data,
    options: { setSubmitting, resetForm, history, id },
  },
}) {
  try {
    const url = id ? `/users/${id}` : `/users`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster('success', `User ${id ? 'update' : 'add'} Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      history.push('/users');
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `${id ? 'Update' : 'Add'} Users Failed`;
    toaster('error', message);
    yield setSubmitting(false);
    yield resetForm();
  }
}

function* onGetSingleUserUser({ payload: { id } }) {
  try {
    const url = `/users/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getSingleUserSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || 'Get single user failed';
    toaster('error', message);
    yield put(getSingleUserFailure({ message }));
  }
}

function* onDeleteUserUser({
  payload: { id, setSelectedUser, setCurrentPage, setHandleList },
}) {
  try {
    const url = `/users/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteUserSuccess(response));
      toaster('success', 'User delete successfully');
      setSelectedUser(null);
      setCurrentPage(1);
      setHandleList(10);
      yield put(getUserList({ page: 1, limit: 10 }));
    }
  } catch (error) {
    const message = error?.response?.data?.message || 'Delete user failed';
    toaster('error', message);
    yield put(deleteUserFailure({ message }));
  }
}

export function* userSaga() {
  yield takeEvery(getUserList.type, onGetUser);
  yield takeEvery(addUserData.type, onAddUserUser);
  yield takeEvery(getSingleUser.type, onGetSingleUserUser);
  yield takeEvery(deleteUser.type, onDeleteUserUser);
}
