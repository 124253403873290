import { call, put, takeEvery } from 'redux-saga/effects';
import { del, get, patch, post } from '../../helpers/api_helper';
import { toaster } from '../../helpers/custom/Toast';
import { getSinglePlayListSuccess } from '../Playlist/playlistSlice';
import {
  addCampaignData,
  addCampaignDataFailure,
  addCampaignDataSuccess,
  deleteCampaign,
  deleteCampaignFailure,
  deleteCampaignSuccess,
  getCampaign,
  getCampaignFailure,
  getCampaignSuccess,
  getSingleCampaign,
  getSingleCampaignFailure,
  getSingleCampaignSuccess,
  storeCampaignData,
} from './campaignSlice';

function* onGetCampaign({
  payload: { page = 1, limit, orgId, status, seachKeyword },
}) {
  try {
    const search = seachKeyword ? `&searchKeyword=${seachKeyword}` : '';
    const url = `/playlist/get-all-campaign?orgId=${orgId}&status=${status}&limit=${limit}&pageNo=${page}${search}`;
    const response = yield call(get, url);
    if (response) {
      response?.campaigns?.forEach((data) => {
        data.view = false;
        data.selected = false;
      });
      yield put(getCampaignSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || 'Get Campaign Failed';
    yield put(getCampaignFailure({ message }));
  }
}

function* onAddCampaign({
  payload: {
    data,
    options: { history, id },
  },
}) {
  try {
    const url = id
      ? `/playlist/update-campaign/${id}`
      : `/playlist/create-campaign`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      // console.log(`🦄 ~ file: saga.js ~ line 47 ~ response`, response);
      toaster('success', `Campaign ${id ? 'update' : 'add'} Successfully`);
      yield put(addCampaignDataSuccess(response));
      yield put(storeCampaignData({ name: 'campaignContent', data: [] }));
      yield put(storeCampaignData({ name: 'createCampaignData', data: {} }));
      yield put(storeCampaignData({ name: 'listDevice', data: [] }));
      history.push(
        `/campaign/successfull-publish-campaign/${response?.playlistId}`
      );
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? 'Update' : 'Add'} Campaign Failed`;
    yield put(addCampaignDataFailure({ message }));
    toaster('error', message);
  }
}

function* onGetSingleCampaign({ payload: { id } }) {
  try {
    const url = `/playlist/get-campaign/${id}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response?.playList) {
      yield put(getSingleCampaignSuccess(response?.playList));
      yield put(
        getSinglePlayListSuccess({
          playlist: response?.playList,
          campaign: true,
        })
      );
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || 'Get single Campaign failed';
    toaster('error', message);
    yield put(getSingleCampaignFailure({ message }));
  }
}

function* onDeleteCampaign({
  payload: { id, setOrgFilter, setCurrentPage, setHandleList },
}) {
  try {
    const url = `/playlist/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteCampaignSuccess(response));
      toaster('success', 'Campaign delete successfully');
      setCurrentPage(1);
      setHandleList(10);
      setOrgFilter('All');
      yield put(
        getCampaign({
          page: 1,
          limit: 1,
          orgId: 'All',
          status: 'All',
          seachKeyword: '',
        })
      );
    }
  } catch (error) {
    const message = error?.response?.data?.message || 'Delete Campaign failed';
    toaster('error', message);
    yield put(deleteCampaignFailure({ message }));
  }
}

export function* CampaignSaga() {
  yield takeEvery(getCampaign.type, onGetCampaign);
  yield takeEvery(addCampaignData.type, onAddCampaign);
  yield takeEvery(getSingleCampaign.type, onGetSingleCampaign);
  yield takeEvery(deleteCampaign.type, onDeleteCampaign);
}
