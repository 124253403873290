import { call, put, takeEvery } from 'redux-saga/effects';
import { del, get, patch, post } from '../../helpers/api_helper';
import { toaster } from '../../helpers/custom/Toast';
import {
  addSlotData,
  deleteSlot,
  deleteSlotFailure,
  deleteSlotSuccess,
  getSlotList,
  getSlotListFailure,
  getSlotListSuccess,
  getSingleSlot,
  getSingleSlotFailure,
  getSingleSlotSuccess,
} from './SlotSlice';

function* onGetSlot({ payload: { page = 1, limit } }) {
  try {
    const url = `/slot/get-slots?limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    yield put(getSlotListSuccess(response));
  } catch (error) {
    const message = error?.response?.data?.message || 'Get slot Failed';
    yield put(getSlotListFailure({ message }));
  }
}

function* onAddSlot({
  payload: {
    data,
    options: { setSubmitting, resetForm, history, id },
  },
}) {
  try {
    const url = id ? `/slot/${id}` : `/slot`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster('success', `Slot ${id ? 'update' : 'add'} Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      // yield put(getSlotList({ page: 1, limit: 10 }));
      history.push('/slots');
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `${id ? 'Update' : 'Add'} Slot Failed`;
    toaster('error', message);
    yield setSubmitting(false);
  }
}

function* onGetSingleSlot({ payload: { id } }) {
  try {
    const url = `/slot/${id}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    yield put(getSingleSlotSuccess(response?.slot));
  } catch (error) {
    const message = error?.response?.data?.message || 'Get single Slot failed';
    toaster('error', message);
    yield put(getSingleSlotFailure({ message }));
  }
}

function* onDeleteSlot({
  payload: { id, setSelectedSlot, setCurrentPage, setHandleList },
}) {
  try {
    const url = `/slot/${id}`;
    const response = yield call(del, url);
    yield put(deleteSlotSuccess(response));
    toaster('success', 'Slot delete successfully');
    setSelectedSlot(null);
    setCurrentPage(1);
    setHandleList(10);
    yield put(getSlotList({ page: 1, limit: 10 }));
  } catch (error) {
    const message = error?.response?.data?.message || 'Delete Slot failed';
    toaster('error', message);
    yield put(deleteSlotFailure({ message }));
  }
}

export function* slotSaga() {
  yield takeEvery(getSlotList.type, onGetSlot);
  yield takeEvery(addSlotData.type, onAddSlot);
  yield takeEvery(getSingleSlot.type, onGetSingleSlot);
  yield takeEvery(deleteSlot.type, onDeleteSlot);
}
