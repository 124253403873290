import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userList: {},
  loading: false,
  singleUserLoading: false,
  logDetails: [],
  error: '',
  singleUser: {},
  adding: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    getUserList: (state, action) => {
      state.loading = true;
    },
    getUserListSuccess: (state, action) => {
      state.userList = action.payload;
      state.loading = false;
    },
    getUserListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addUserData: (state, action) => {},
    getSingleUser: (state, action) => {
      state.singleUserLoading = true;
    },
    getSingleUserSuccess: (state, action) => {
      state.singleUser = action.payload?.users;
      state.logDetails = action.payload?.logDetails;
      state.singleUserLoading = false;
    },
    getSingleUserFailure: (state, action) => {
      state.error = action.payload.message;
      state.singleUserLoading = false;
    },
    deleteUser: (state, action) => {
      state.adding = true;
    },
    deleteUserSuccess: (state, action) => {
      state.adding = false;
    },
    deleteUserFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getUserList,
  getUserListSuccess,
  getUserListFailure,
  addUserData,
  getSingleUser,
  getSingleUserSuccess,
  getSingleUserFailure,
  deleteUser,
  deleteUserSuccess,
  deleteUserFailure,
} = userSlice.actions;

export default userSlice.reducer;
