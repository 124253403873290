import { call, put, takeEvery } from 'redux-saga/effects';
import { del, get, patch, post } from '../../helpers/api_helper';
import { toaster } from '../../helpers/custom/Toast';
import {
  addPackageData,
  deletePackage,
  deletePackageFailure,
  deletePackageSuccess,
  getPackageList,
  getPackageListFailure,
  getPackageListSuccess,
  getSinglePackage,
  getSinglePackageFailure,
  getSinglePackageSuccess,
} from './PackageSlice';

function* onGetPackage({ payload: { page = 1, limit } }) {
  try {
    const url = `/package/${limit}/${page}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getPackageListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || 'Get package Failed';
    yield put(getPackageListFailure({ message }));
  }
}

function* onAddPackage({
  payload: {
    data,
    options: { setSubmitting, resetForm, history, id },
  },
}) {
  try {
    const url = id ? `/package/${id}` : `/package`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster('success', `Package ${id ? 'update' : 'add'} Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      yield put(getPackageList({ page: 1, limit: 10 }));
      history.push('/packages');
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? 'Update' : 'Add'} Package Failed`;
    toaster('error', message);
    yield setSubmitting(false);
  }
}

function* onGetSinglePackage({ payload: { id } }) {
  try {
    const url = `/package/${id}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      yield put(getSinglePackageSuccess(response?.package));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || 'Get single package failed';
    toaster('error', message);
    yield put(getSinglePackageFailure({ message }));
  }
}

function* onDeletePackage({
  payload: { id, setSelectedUser, setCurrentPage, setHandleList },
}) {
  try {
    const url = `/package/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deletePackageSuccess(response));
      toaster('success', 'Package delete successfully');
      setSelectedUser(null);
      setCurrentPage(1);
      setHandleList(10);
      yield put(getPackageList({ page: 1, limit: 10 }));
    }
  } catch (error) {
    const message = error?.response?.data?.message || 'Delete package failed';
    toaster('error', message);
    yield put(deletePackageFailure({ message }));
  }
}

export function* packageSaga() {
  yield takeEvery(getPackageList.type, onGetPackage);
  yield takeEvery(addPackageData.type, onAddPackage);
  yield takeEvery(getSinglePackage.type, onGetSinglePackage);
  yield takeEvery(deletePackage.type, onDeletePackage);
}
