import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  deviceList: {},
  devices: [],
  loading: false,
  error: '',
  singleDevice: {},
  adding: false,
  verifiedDevice: {},
};

const deviceSlice = createSlice({
  name: 'device',
  initialState: initialState,
  reducers: {
    storeDeviceData: (state, action) => {
      state[action.payload.name] = action.payload.data;
    },
    getDeviceList: (state, action) => {
      state.loading = true;
    },
    getDeviceListSuccess: (state, action) => {
      state.deviceList = action.payload;
      state.devices = action.payload?.devices;
      state.loading = false;
    },
    getDeviceListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addDeviceData: (state, action) => {},
    getSingleDevice: (state, action) => {
      state.loading = true;
    },
    getSingleDeviceSuccess: (state, action) => {
      state.singleDevice = action.payload;
      state.loading = false;
    },
    getSingleDeviceFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteDevice: (state, action) => {
      state.adding = true;
    },
    deleteDeviceSuccess: (state, action) => {
      state.adding = false;
    },
    deleteDeviceFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
    verifyDevice: (state, action) => {
      state.adding = true;
    },
    verifyDeviceSuccess: (state, action) => {
      state.adding = false;
      state.verifiedDevice = action.payload;
    },
    verifyDeviceFailed: (state, action) => {
      state.adding = false;
      state.error = action.payload.message;
    },
    upDateDevice: (state, action) => {
      state.adding = true;
    },
    upDateDeviceSuccess: (state, action) => {
      state.adding = false;
    },
    upDateDeviceFailure: (state, action) => {
      state.adding = false;
      state.error = action.payload.message;
    },
  },
});

export const {
  getDeviceList,
  getDeviceListSuccess,
  getDeviceListFailure,
  addDeviceData,
  getSingleDevice,
  getSingleDeviceSuccess,
  getSingleDeviceFailure,
  deleteDevice,
  deleteDeviceSuccess,
  deleteDeviceFailure,
  storeDeviceData,
  verifyDevice,
  verifyDeviceSuccess,
  verifyDeviceFailed,
  upDateDevice,
  upDateDeviceSuccess,
  upDateDeviceFailure,
} = deviceSlice.actions;

export default deviceSlice.reducer;
