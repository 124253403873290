import { call, put, takeEvery } from 'redux-saga/effects';
import { del, get, patch, post } from '../../helpers/api_helper';
import { toaster } from '../../helpers/custom/Toast';
import {
  addBill,
  addBillFailure,
  addBillSuccess,
  deleteBill,
  deleteBillFailure,
  deleteBillSuccess,
  getBill,
  getBillFailure,
  getBillReport,
  getBillReportFailure,
  getBillReportSuccess,
  getBillSuccess,
  getContentReport,
  getContentReportFailure,
  getContentReportSuccess,
  getContentSummary,
  getContentSummaryFailure,
  getContentSummarySuccess,
  getSingleBill,
  getSingleBillFailure,
  getSingleBillSuccess,
  payBill,
  payBillFailure,
  payBillSuccess,
} from './billSlice';

function* onGetBill({
  payload: { page = 1, limit, orgId, status, seachKeyword },
}) {
  try {
    const url = `/bill/get-bill-list?orgId=${orgId}&status=${status}&seachKeyword=${seachKeyword}&limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    // console.log(`🦄 ~ file: saga.js ~ line 29 ~ response`, response);
    if (response) {
      yield put(getBillSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || 'Get bill failed';
    yield put(getBillFailure({ message }));
  }
}

function* onGetBillReport({ payload: { orgId, status } }) {
  try {
    const url = `/bill/get-bill-report?orgId=${orgId}&status=${status}`;
    const response = yield call(get, url);
    // console.log(`🦄 ~ file: saga.js ~ line 29 ~ response`, response);
    if (response) {
      yield put(getBillReportSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || 'Get bill report Failed';
    yield put(getBillReportFailure({ message }));
  }
}

function* onGetContentReport({
  payload: { orgId, reportType, contentId, fromDate, toDate },
}) {
  try {
    const url = `/content/get-content-displayed-report?reportType=${reportType}&orgId=${orgId}&contentId=${contentId}&fromDate=${fromDate}&toDate=${toDate}`;
    const response = yield call(get, url);
    // console.log(`🦄 ~ file: saga.js ~ line 29 ~ response`, response);
    if (response) {
      yield put(getContentReportSuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || 'Get content report Failed';
    yield put(getContentReportFailure({ message }));
  }
}

function* onGetContentSummary({ payload: { orgId, contentId } }) {
  try {
    const url = `/bill/get-content-summary-for-bill-generate?orgId=${orgId}&contentId=${contentId}`;
    const response = yield call(get, url);
    if (response) {
      response?.contentSummary?.forEach((data) => {
        data.view = false;
        data.selected = false;
      });
      yield put(getContentSummarySuccess(response));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || 'Get Content Summary Failed';
    yield put(getContentSummaryFailure({ message }));
  }
}

function* onAddBill({
  payload: {
    data,
    options: { history, id },
  },
}) {
  try {
    const url = id ? `/playList/${id}` : `/bill/bill-create`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster('success', `Bill ${id ? 'update' : 'add'} Successfully`);
      yield put(addBillSuccess(response));
      history.push(`/bill-list`);
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || `${id ? 'Update' : 'Add'} Bill Failed`;
    yield put(addBillFailure({ message }));
    toaster('error', message);
  }
}

function* onPayBill({ payload: { data, resetFile } }) {
  try {
    const url = `/bill/pay-bill/`;
    const response = yield call(post, url, data);
    if (response) {
      toaster('success', `Bill add Successfully`);
      yield put(payBillSuccess(response));
      resetFile();
      yield put(
        getBill({
          page: 1,
          limit: 10,
          orgId: 'All',
          status: 'All',
          seachKeyword: '',
        })
      );
    }
  } catch (error) {
    const message = error?.response?.data?.message || `Add Bill Failed`;
    yield put(payBillFailure({ message }));
    toaster('error', message);
  }
}

function* onGetSingleBill({ payload: { id } }) {
  try {
    const url = `/bill/${id}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getSingleBillSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || 'Get single bill failed';
    toaster('error', message);
    yield put(getSingleBillFailure({ message }));
  }
}

function* onDeleteBill({
  payload: { id, setOrgFilter, setCurrentPage, setHandleList },
}) {
  try {
    const url = `/playList/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteBillSuccess(response));
      toaster('success', 'PlayList delete successfully');
      setCurrentPage(1);
      setHandleList(10);
      setOrgFilter('All');
      yield put(
        getBill({
          page: 1,
          limit: 1,
          orgId: 'All',
          status: 'All',
          seachKeyword: '',
        })
      );
    }
  } catch (error) {
    const message = error?.response?.data?.message || 'Delete PlayList failed';
    toaster('error', message);
    yield put(deleteBillFailure({ message }));
  }
}

export function* billSaga() {
  yield takeEvery(getBill.type, onGetBill);
  yield takeEvery(getBillReport.type, onGetBillReport);
  yield takeEvery(getContentReport.type, onGetContentReport);
  yield takeEvery(addBill.type, onAddBill);
  yield takeEvery(payBill.type, onPayBill);
  yield takeEvery(getSingleBill.type, onGetSingleBill);
  yield takeEvery(deleteBill.type, onDeleteBill);
  yield takeEvery(getContentSummary.type, onGetContentSummary);
}
