import { call, put, takeEvery } from 'redux-saga/effects';
import { del, get, patch, post } from '../../helpers/api_helper';
import { toaster } from '../../helpers/custom/Toast';
import {
  addDeviceData,
  deleteDevice,
  deleteDeviceFailure,
  deleteDeviceSuccess,
  getDeviceList,
  getDeviceListFailure,
  getDeviceListSuccess,
  getSingleDevice,
  getSingleDeviceFailure,
  getSingleDeviceSuccess,
  upDateDevice,
  upDateDeviceFailure,
  upDateDeviceSuccess,
  verifyDevice,
  verifyDeviceFailed,
  verifyDeviceSuccess,
} from './deviceSlice';

function* onGetDevice({
  payload: { page = 1, limit, orgId, status, OTPVerify, selectedDevice },
}) {
  try {
    const url = `/device/get-devices?orgId=${orgId}&status=${status}&otpVerification=${OTPVerify}&limit=${limit}&pageNo=${page}`;
    const response = yield call(get, url);
    if (response) {
      const findId = selectedDevice?.map((data) => data?._id);
      response?.devices?.forEach((data) => {
        data.view = false;
        data.selected = findId?.includes(data?._id) ? true : false;
      });
      yield put(getDeviceListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || 'Get Device Failed';
    yield put(getDeviceListFailure({ message }));
  }
}

function* onAddDevice({
  payload: {
    data,
    options: { setSubmitting, resetForm, history, id },
  },
}) {
  try {
    const url = id ? `/device/${id}` : `/device`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster('success', `Device ${id ? 'update' : 'add'} Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      yield put(getDeviceList({ page: 1, limit: 10 }));
      history.push('/device');
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? 'Update' : 'Add'} Device Failed`;
    toaster('error', message);
    yield setSubmitting(false);
  }
}

function* onGetSingleDevice({ payload: { id } }) {
  try {
    const url = `/device/${id}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      yield put(getSingleDeviceSuccess(response?.device));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || 'Get single Device failed';
    toaster('error', message);
    yield put(getSingleDeviceFailure({ message }));
  }
}

function* onDeleteDevice({
  payload: { id, setSelectedUser, setCurrentPage, setHandleList },
}) {
  try {
    const url = `/device/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteDeviceSuccess(response));
      toaster('success', 'Device delete successfully');
      setSelectedUser(null);
      setCurrentPage(1);
      setHandleList(10);
      yield put(getDeviceList({ page: 1, limit: 10 }));
    }
  } catch (error) {
    const message = error?.response?.data?.message || 'Delete Device failed';
    toaster('error', message);
    yield put(deleteDeviceFailure({ message }));
  }
}

function* onVerifyDevice({ payload: { data, setOtpSubmit } }) {
  try {
    const url = `/device/pairing-code-verification`;
    const response = yield call(post, url, data);
    if (response?.device) {
      yield put(verifyDeviceSuccess(response?.device));
      setOtpSubmit(false);
    } else {
      toaster('error', 'Pairing Code is not valid!!!');
      yield put(verifyDeviceFailed({ message: '' }));
    }
  } catch (error) {
    const message = error?.response?.data?.message || 'Verify Device failed';
    toaster('error', message);
    yield put(verifyDeviceFailed({ message }));
  }
}

function* onUpdateDevice({
  payload: { id, data, setOpenFirst, setSubmitting },
}) {
  try {
    const url = `/device/${id}`;
    const response = yield call(patch, url, data);
    if (response) {
      yield put(upDateDeviceSuccess(response));
      toaster('success', 'Device added successfully');
      setOpenFirst(false);
      setSubmitting(false);
      yield put(
        getDeviceList({
          page: 1,
          limit: 10,
          orgId: 'All',
          status: 'All',
          OTPVerify: 'All',
        })
      );
    }
  } catch (error) {
    const message = error?.response?.data?.message || 'Update Device failed';
    toaster('error', message);
    yield put(upDateDeviceFailure({ message }));
    setSubmitting(false);
  }
}

export function* DeviceSaga() {
  yield takeEvery(getDeviceList.type, onGetDevice);
  yield takeEvery(addDeviceData.type, onAddDevice);
  yield takeEvery(getSingleDevice.type, onGetSingleDevice);
  yield takeEvery(deleteDevice.type, onDeleteDevice);
  yield takeEvery(verifyDevice.type, onVerifyDevice);
  yield takeEvery(upDateDevice.type, onUpdateDevice);
}
