import { call, put, takeEvery } from 'redux-saga/effects';
import { del, get, patch, post } from '../../helpers/api_helper';
import { toaster } from '../../helpers/custom/Toast';
import {
  addContentData,
  deleteContent,
  deleteContentFailure,
  deleteContentSuccess,
  getContentList,
  getContentListFailure,
  getContentListSuccess,
  getSingleContent,
  getSingleContentFailure,
  getSingleContentSuccess,
} from './contentSlice';

function* onGetContent({
  payload: { page = 1, limit, orgId, categoryId, listId, seachKeyword },
}) {
  try {
    const search = seachKeyword ? `&searchKeyword=${seachKeyword}` : '';
    const url = `/content/get-contents?orgId=${orgId}&categoryId=${categoryId}&limit=${limit}&pageNo=${page}${search}`;
    const response = yield call(get, url);
    if (response) {
      const { contents, ...rest } = response;
      contents?.forEach((data) => {
        data.view = false;
        data.selected = false;
      });
      if (listId) {
        const data = contents?.filter((item) => !listId?.includes(item._id));
        yield put(getContentListSuccess({ ...rest, contents: data }));
      } else {
        yield put(getContentListSuccess(response));
      }
    }
  } catch (error) {
    const message = error?.response?.data?.message || 'Get content Failed';
    yield put(getContentListFailure({ message }));
  }
}

function* onAddContent({
  payload: {
    data,
    options: { setSubmitting, resetForm, history, id },
  },
}) {
  try {
    const url = id
      ? `/content/update-content/${id}`
      : `/content/create-content`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster('success', `Content ${id ? 'update' : 'add'} Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      // yield put(getContentList({ page: 1, limit: 10 }));
      history.push('/content');
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? 'Update' : 'Add'} content Failed`;
    toaster('error', message);
    yield setSubmitting(false);
  }
}

function* onGetSingleContent({ payload: { id } }) {
  try {
    const url = `/content/get-content-by-id/${id}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      yield put(getSingleContentSuccess(response?.content));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || 'Get single content failed';
    toaster('error', message);
    yield put(getSingleContentFailure({ message }));
  }
}

function* onDeleteContent({ payload: { id, toggle } }) {
  try {
    const url = `/content/delete-content/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteContentSuccess(response));
      toaster('success', 'Content delete successfully');
      toggle();
      const orgId = 'All';
      const categoryId = 'All';
      yield put(getContentList({ page: 1, limit: 10, orgId, categoryId }));
    }
  } catch (error) {
    const message = error?.response?.data?.message || 'Delete content failed';
    toaster('error', message);
    yield put(deleteContentFailure({ message }));
  }
}

export function* contentSaga() {
  yield takeEvery(getContentList.type, onGetContent);
  yield takeEvery(addContentData.type, onAddContent);
  yield takeEvery(getSingleContent.type, onGetSingleContent);
  yield takeEvery(deleteContent.type, onDeleteContent);
}
