import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  contentList: {},
  contents: [],
  loading: false,
  error: '',
  singleContent: {},
  adding: false,
};

const contentSlice = createSlice({
  name: 'content',
  initialState: initialState,
  reducers: {
    storeContentData: (state, action) => {
      state[action.payload.name] = action.payload.data;
    },
    getContentList: (state, action) => {
      state.loading = true;
    },
    getContentListSuccess: (state, action) => {
      state.contentList = action.payload;
      state.contents = action.payload?.contents;
      state.loading = false;
    },
    getContentListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addContentData: (state, action) => {},
    getSingleContent: (state, action) => {
      state.loading = true;
    },
    getSingleContentSuccess: (state, action) => {
      state.singleContent = action.payload;
      state.loading = false;
    },
    getSingleContentFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteContent: (state, action) => {
      state.adding = true;
    },
    deleteContentSuccess: (state, action) => {
      state.adding = false;
    },
    deleteContentFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getContentList,
  getContentListSuccess,
  getContentListFailure,
  addContentData,
  getSingleContent,
  getSingleContentSuccess,
  getSingleContentFailure,
  deleteContent,
  deleteContentSuccess,
  deleteContentFailure,
  storeContentData,
} = contentSlice.actions;

export default contentSlice.reducer;
