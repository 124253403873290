import { call, put, takeEvery } from 'redux-saga/effects';
import { del, get } from '../../helpers/api_helper';
import { toaster } from '../../helpers/custom/Toast';
import {
  addPlayListData,
  addPlayListDataFailure,
  // addPlayListDataSuccess,
  deletePlayList,
  deletePlayListFailure,
  deletePlayListSuccess,
  getPlayList,
  getPlayListFailure,
  getPlayListSuccess,
  getSinglePlayList,
  getSinglePlayListFailure,
  getSinglePlayListSuccess,
  // storePlaylistData,
} from './playlistSlice';

function* onGetPlayList({
  payload: { page = 1, limit, orgId, status, seachKeyword },
}) {
  try {
    const search = seachKeyword ? `&searchKeyword=${seachKeyword}` : '';
    const url = `/playlist/get-all-playlist?orgId=${orgId}&status=${status}&limit=${limit}&pageNo=${page}${search}`;
    const response = yield call(get, url);
    if (response) {
      response?.PlayLists?.forEach((data) => {
        data.view = false;
        data.selected = false;
      });
      yield put(getPlayListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || 'Get PlayList Failed';
    yield put(getPlayListFailure({ message }));
  }
}

function* onAddPlayList({
  payload: {
    data,
    options: { history, id, socket },
  },
}) {
  try {
    yield socket.emit(
      id ? 'join_room_plylist_added' : 'join_room_plylist_added',
      {
        data,
      }
    );
    // history.push(`/playlist`);
    // const url = id ? `/playList/${id}` : `/playlist/create-playlist`;
    // const response = yield call(id ? patch : post, url, data);
    // if (response) {
    //   toaster('success', `PlayList ${id ? 'update' : 'add'} Successfully`);
    //   console.log(`🦄 ~ file: saga.js ~ line 47 ~ response`, response);
    //   yield put(addPlayListDataSuccess(response));
    //   yield put(storePlaylistData({ name: 'playListContent', data: [] }));
    //   yield put(storePlaylistData({ name: 'createPlaylistData', data: {} }));
    //   yield put(storePlaylistData({ name: 'listDevice', data: [] }));
    //   history.push(
    //     `/playlist/successfull-pulish-playlist/${response?.playlistId}`
    //   );
    // }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? 'Update' : 'Add'} PlayList Failed`;
    yield put(addPlayListDataFailure({ message }));
    toaster('error', message);
  }
}

function* onGetSinglePlaylist({ payload: { id } }) {
  try {
    const url = `/playlist/get-playlist/${id}`;
    const response = yield call(get, url);
    // console.log(`response`, response);
    if (response) {
      yield put(getSinglePlayListSuccess(response?.playList));
    }
  } catch (error) {
    const message =
      error?.response?.data?.message || 'Get single PlayList failed';
    toaster('error', message);
    yield put(getSinglePlayListFailure({ message }));
  }
}

function* onDeletePlayList({
  payload: { id, setOrgFilter, setCurrentPage, setHandleList },
}) {
  try {
    const url = `/playList/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deletePlayListSuccess(response));
      toaster('success', 'PlayList delete successfully');
      setCurrentPage(1);
      setHandleList(10);
      setOrgFilter('All');
      yield put(
        getPlayList({
          page: 1,
          limit: 1,
          orgId: 'All',
          status: 'All',
          seachKeyword: '',
        })
      );
    }
  } catch (error) {
    const message = error?.response?.data?.message || 'Delete PlayList failed';
    toaster('error', message);
    yield put(deletePlayListFailure({ message }));
  }
}

export function* playListSaga() {
  yield takeEvery(getPlayList.type, onGetPlayList);
  yield takeEvery(addPlayListData.type, onAddPlayList);
  yield takeEvery(getSinglePlayList.type, onGetSinglePlaylist);
  yield takeEvery(deletePlayList.type, onDeletePlayList);
}
