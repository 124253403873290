import { call, put, takeEvery } from 'redux-saga/effects';
import { axiosApi, post } from '../../../helpers/api_helper';
import { toaster } from '../../../helpers/custom/Toast';
import { login, loginFailure, loginSuccess, logOutUser } from './loginSlice';

function* onLoginUser({ payload: { history, data } }) {
  try {
    const url = '/users/login';
    const response = yield call(post, url, data);
    // console.log(`function*onLoginUser ~ response`, response);
    if (response) {
      yield put(
        loginSuccess({
          token: response?.token,
          role: response?.user?.role,
          user: response?.user,
        })
      );
      axiosApi.defaults.headers.common[
        'Authorization'
      ] = `Bearer ${response?.token}`;
      toaster('success', 'Login Successful');
      history.push('/');
    }
  } catch (err) {
    const message = err?.response?.data?.message || 'Login Failed';
    yield put(loginFailure({ message }));
  }
}

function* onLogOutUser({ payload: { history } }) {
  yield history.push('/login');
}

// eslint-disable-next-line import/prefer-default-export
export function* loginSaga() {
  yield takeEvery(login.type, onLoginUser);
  yield takeEvery(logOutUser.type, onLogOutUser);
}
