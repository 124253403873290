import { call, put, takeEvery } from 'redux-saga/effects';
import { del, get, patch, post } from '../../helpers/api_helper';
import { toaster } from '../../helpers/custom/Toast';
import {
  addCategoryData,
  deleteCategory,
  deleteCategoryFailure,
  deleteCategorySuccess,
  getCategoryList,
  getCategoryListFailure,
  getCategoryListSuccess,
  getSingleCategory,
  getSingleCategoryFailure,
  getSingleCategorySuccess,
} from './CategorySlice';

function* onGetCategory({ payload: { page = 1, limit } }) {
  try {
    const url = `/category/${limit}/${page}`;
    const response = yield call(get, url);
    if (response) {
      yield put(getCategoryListSuccess(response));
    }
  } catch (error) {
    const message = error?.response?.data?.message || 'Get category Failed';
    yield put(getCategoryListFailure({ message }));
  }
}

function* onAddCategory({
  payload: {
    data,
    options: { setSubmitting, resetForm, toggle, id },
  },
}) {
  try {
    const url = id ? `/category/${id}` : `/category`;
    const response = yield call(id ? patch : post, url, data);
    if (response) {
      toaster('success', `Category ${id ? 'update' : 'add'} Successfully`);
      yield setSubmitting(false);
      yield resetForm();
      yield put(getCategoryList({ page: 1, limit: 10 }));
      toggle();
    }
  } catch (error) {
    const message =
      error?.response?.data?.message ||
      `${id ? 'Update' : 'Add'} Category Failed`;
    toaster('error', message);
    yield setSubmitting(false);
  }
}

function* onGetSingleCategory({ payload: { id } }) {
  try {
    const url = `/category/${id}`;
    const response = yield call(get, url);
    console.log(`response`, response);
    if (response) {
      yield put(getSingleCategorySuccess(response?.category));
    }
  } catch (error) {
    const message = error?.response?.data?.message || 'Get single user failed';
    toaster('error', message);
    yield put(getSingleCategoryFailure({ message }));
  }
}

function* onDeleteCategory({
  payload: { id, setSelectedUser, setCurrentPage, setHandleList },
}) {
  try {
    const url = `/category/${id}`;
    const response = yield call(del, url);
    if (response) {
      yield put(deleteCategorySuccess(response));
      toaster('success', 'Category delete successfully');
      setSelectedUser(null);
      setCurrentPage(1);
      setHandleList(10);
      yield put(getCategoryList({ page: 1, limit: 10 }));
    }
  } catch (error) {
    const message = error?.response?.data?.message || 'Delete category failed';
    toaster('error', message);
    yield put(deleteCategoryFailure({ message }));
  }
}

export function* categorySaga() {
  yield takeEvery(getCategoryList.type, onGetCategory);
  yield takeEvery(addCategoryData.type, onAddCategory);
  yield takeEvery(getSingleCategory.type, onGetSingleCategory);
  yield takeEvery(deleteCategory.type, onDeleteCategory);
}
