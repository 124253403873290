import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  campaignData: {},
  campaigns: [],
  campaignContent: [],
  listDevice: [],
  screenLayout: '',
  createCampaignData: {},
  loading: false,
  error: '',
  singleCampaign: {},
  adding: false,
};

const campaignSlice = createSlice({
  name: 'Campaign',
  initialState: initialState,
  reducers: {
    storeCampaignData: (state, action) => {
      state[action.payload.name] = action.payload.data;
    },
    getCampaign: (state, action) => {
      state.loading = true;
    },
    getCampaignSuccess: (state, action) => {
      state.campaignData = action.payload;
      state.campaigns = action.payload?.playLists;
      state.loading = false;
    },
    getCampaignFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addCampaignData: (state, action) => {
      state.adding = true;
    },
    addCampaignDataSuccess: (state, action) => {
      state.adding = false;
      // state.createCampaignData = action.payload;
    },
    addCampaignDataFailure: (state, action) => {
      state.adding = false;
      state.error = action.payload.message;
    },
    getSingleCampaign: (state, action) => {
      state.loading = true;
    },
    getSingleCampaignSuccess: (state, action) => {
      const {
        contents,
        devices,
        screenLayout,
        orgId,
        categoryId,
        fromDate,
        toDate,
        periodTime,
        ...rest
      } = action.payload;
      state.campaignContent = contents;
      if (devices?.length) {
        devices?.forEach((data) => {
          data.selected = false;
        });
      }
      state.singleCampaign = action.payload;
      state.screenLayout = screenLayout;
      state.listDevice = devices;
      let timeType = 'fixed';
      if (periodTime) {
        timeType = 'schedule';
      }
      state.createCampaignData = {
        ...rest,
        fromDate: fromDate?.split('T')?.[0],
        toDate: toDate?.split('T')?.[0],
        orgId: orgId?._id,
        categoryId: categoryId?._id,
        periodTime,
        timeType,
      };
      state.loading = false;
    },
    getSingleCampaignFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteCampaign: (state, action) => {
      state.adding = true;
    },
    deleteCampaignSuccess: (state, action) => {
      state.adding = false;
    },
    deleteCampaignFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getCampaign,
  getCampaignSuccess,
  getCampaignFailure,
  addCampaignData,
  addCampaignDataSuccess,
  addCampaignDataFailure,
  getSingleCampaign,
  getSingleCampaignSuccess,
  getSingleCampaignFailure,
  deleteCampaign,
  deleteCampaignSuccess,
  deleteCampaignFailure,
  storeCampaignData,
} = campaignSlice.actions;

export default campaignSlice.reducer;
