import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  slotList: {},
  loading: false,
  error: '',
  singleSlot: {},
  adding: false,
};

const slotSlice = createSlice({
  name: 'slots',
  initialState: initialState,
  reducers: {
    storeSlotData: (state, action) => {
      state[action.payload.name] = action.payload.data;
    },
    getSlotList: (state, action) => {
      state.loading = true;
    },
    getSlotListSuccess: (state, action) => {
      state.slotList = action.payload;
      state.loading = false;
    },
    getSlotListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addSlotData: (state, action) => {},
    getSingleSlot: (state, action) => {
      state.loading = true;
    },
    getSingleSlotSuccess: (state, action) => {
      state.singleSlot = action.payload;
      state.loading = false;
    },
    getSingleSlotFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteSlot: (state, action) => {
      state.adding = true;
    },
    deleteSlotSuccess: (state, action) => {
      state.adding = false;
    },
    deleteSlotFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getSlotList,
  getSlotListSuccess,
  getSlotListFailure,
  addSlotData,
  getSingleSlot,
  getSingleSlotSuccess,
  getSingleSlotFailure,
  deleteSlot,
  deleteSlotSuccess,
  deleteSlotFailure,
  storeSlotData,
} = slotSlice.actions;

export default slotSlice.reducer;
