import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  categoryList: {},
  loading: false,
  error: '',
  singleUser: {},
  adding: false,
};

const categorySlice = createSlice({
  name: 'category',
  initialState: initialState,
  reducers: {
    getCategoryList: (state, action) => {
      state.loading = true;
    },
    getCategoryListSuccess: (state, action) => {
      state.categoryList = action.payload;
      state.loading = false;
    },
    getCategoryListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addCategoryData: (state, action) => {},
    getSingleCategory: (state, action) => {
      state.loading = true;
    },
    getSingleCategorySuccess: (state, action) => {
      state.singleUser = action.payload;
      state.loading = false;
    },
    getSingleCategoryFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deleteCategory: (state, action) => {
      state.adding = true;
    },
    deleteCategorySuccess: (state, action) => {
      state.adding = false;
    },
    deleteCategoryFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getCategoryList,
  getCategoryListSuccess,
  getCategoryListFailure,
  addCategoryData,
  getSingleCategory,
  getSingleCategorySuccess,
  getSingleCategoryFailure,
  deleteCategory,
  deleteCategorySuccess,
  deleteCategoryFailure,
} = categorySlice.actions;

export default categorySlice.reducer;
