import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  packageList: {},
  loading: false,
  error: '',
  singlePackage: {},
  adding: false,
};

const packageSlice = createSlice({
  name: 'package',
  initialState: initialState,
  reducers: {
    getPackageList: (state, action) => {
      state.loading = true;
    },
    getPackageListSuccess: (state, action) => {
      state.packageList = action.payload;
      state.loading = false;
    },
    getPackageListFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    addPackageData: (state, action) => {},
    getSinglePackage: (state, action) => {
      state.loading = true;
    },
    getSinglePackageSuccess: (state, action) => {
      state.singlePackage = action.payload;
      state.loading = false;
    },
    getSinglePackageFailure: (state, action) => {
      state.error = action.payload.message;
      state.loading = false;
    },
    deletePackage: (state, action) => {
      state.adding = true;
    },
    deletePackageSuccess: (state, action) => {
      state.adding = false;
    },
    deletePackageFailure: (state, action) => {
      state.error = action.payload.message;
      state.adding = false;
    },
  },
});

export const {
  getPackageList,
  getPackageListSuccess,
  getPackageListFailure,
  addPackageData,
  getSinglePackage,
  getSinglePackageSuccess,
  getSinglePackageFailure,
  deletePackage,
  deletePackageSuccess,
  deletePackageFailure,
} = packageSlice.actions;

export default packageSlice.reducer;
