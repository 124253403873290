import { all, fork } from 'redux-saga/effects';
import { loginSaga } from './Auth/Login/saga';
import { billSaga } from './Bill/saga';
import { CampaignSaga } from './Campaign/saga';
import { categorySaga } from './Category/saga';
import { contentSaga } from './Content/saga';
import { DeviceSaga } from './Device/saga';
import LayoutSaga from './Layout/saga';
import { packageSaga } from './Package/saga';
import { playListSaga } from './Playlist/saga';
import { slotSaga } from './Slot/saga';
import { userSaga } from './User/saga';

export default function* rootSaga() {
  yield all([
    fork(loginSaga),
    fork(LayoutSaga),
    fork(userSaga),
    fork(categorySaga),
    fork(packageSaga),
    fork(contentSaga),
    fork(DeviceSaga),
    fork(playListSaga),
    fork(CampaignSaga),
    fork(billSaga),
    fork(slotSaga),
  ]);
}
